module.exports = {
  pathPrefix: '',
  siteUrl: 'https://qmau.me',
  siteTitle: 'Mầu - System Engineer | Outdoorsman @ qmau.me',
  siteDescription: 'Hành trình của một kỹ sư công nghệ thông tin với Bạc sỉu ☕ dành cho những ý tưởng nghiêm túc, vài ly Trà đá 🍵 bên dăm ba mẩu chuyện vỉa hè và những cốc bia hơi 🍺 với những người bạn mới. ',
  author: 'Ha Quang Mau',
  postsForArchivePage: 3,
  defaultLanguage: 'vi',
  facebookAppId: process.env.FACEBOOK_APP_ID || '370447240029909',
  pages: {
    home: '/',
    blog: 'blog',
    contact: 'contact',
    resume: 'resume',
    tag: 'tags',
  },
  social: {
    github: 'https://github.com/qmau94',
    facebook: 'https://fb.me/qmau94',
    messenger: 'https://m.me/qmau94',
    twitter: 'https://twitter.com/qmau94',
    instagram: 'https://www.instagram.com/q.m.a.u/',
    rss: '/rss.xml',
  },
  contactFormUrl: process.env.CONTACT_FORM_ENDPOINT || 'https://getform.io/f/52703bee-00b7-4dcd-9d94-6eefeab04712',
  googleAnalyticTrackingId: process.env.GA_TRACKING_ID || 'UA-102615102-1',
  tags: {
    bacsiu: {
      name: 'Bạc sỉu',
      description: 'Những ý tưởng nghiêm túc cạnh những ly bạc xỉu ☕ ',
      color: '#dd3431',
    },
    biahoi: {
      name: 'Bia hơi',
      description: 'Những người bạn bên cốc bia 🍺 trong những cuộc hành trình mới ',
      color: '#dd3431',
    },
    thethao: {
      name: 'Thể thao',
      description: 'Những câu chuyện về thể thao',
      color: '#dd3431',
    },
    trada: {
      name: 'Trà đá',
      description: 'Dăm ba mẩu chuyện vỉa hè bên ly trà đá 🍵 ',
      color: '#dd3431',
    },
    lifeinjapan: {
      name: 'Life in Japan',
      description: 'Trải nghiệm sống ở Nhật 🇯🇵',
      color: '#dd3431',
    },
    javascript: {
      name: 'javascript',
      description: 'JavaScript là ngôn ngữ lập trình phổ biến nhất trên thế giới trong suốt 20 năm qua. Nó cũng là một trong số 3 ngôn ngữ chính của lập trình web.',
      color: '#f0da50',
    },
    aws: {
      name: 'AWS',
      description: 'Amazon Web Services (AWS) là nền tảng đám mây toàn diện và được sử dụng rộng rãi nhất, cung cấp trên 175 dịch vụ đầy đủ tính năng từ các trung tâm dữ liệu trên toàn thế giới.',
      color: '#FF9900',
    },
    aws1: {
      name: 'AWS',
      description: 'AWS series #1',
      color: '#FF9900',
    },
    hanoi: {
      name: 'Hà Nội',
      description: 'The one and the only',
      color: '#0055B3',
    },
    ted: {
      name: 'TedTalks',
      description: 'Các bài viết tổng hợp Ted Talks với mục đích cá nhân (phi lợi nhuận)',
      color: '#FFFFFF',
    },
    gatsby: {
      name: 'Gatsby.js',
      description: 'Framework sinh web tĩnh được xây dựng bằng ReactJS ',
      color: '#6f309f',
    },
    rails: {
      name: 'Rails',
      description: 'Ruby On rails là một Framework cho phép phát triển ứng dụng Web được viết bằng ruby',
      color: '#dd3431',
    },
    docker: {
      name: 'Docker',
      description: 'Docker là một nền tảng cho phép tạo các môi trường độc lập và tách biệt để khởi chạy và phát triển ứng dụng (container).',
      color: '#dd3431',
    },
    books: {
      name: 'Books',
      description: 'I shall be miserable if I have not an excellent library.',
      color: '#FF9900',
    },
  },
};
